@import "antd/es/style/themes/default.less";
@import "antd/dist/antd.less";

// https://ant.design/docs/react/customize-theme
@primary-color: #77263c;

// styling for timetable
html {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.header-col:last-child {
  border-right-width: 1px;
}

.header-col {
  border-color: #e9e9e9;
  border-style: solid;
  border-width: 0px 0px 1px 1px;
}

.calendar {
  overflow: auto;
  padding: 0px;
}

.calendar > li {
  display: grid;
  width: fit-content;
}

.employee-overlap {
  position: sticky;
  left: 0;
  background: white;
  z-index: 30;
}

.date-overlap {
  position: sticky;
  top: 0;
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  z-index: 20;
  height: 48;
  text-align: center;
}
